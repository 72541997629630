export const homeServiceData = [
  {
    imgFile: "billboard",
    title: "ADVERTISING",
    topics: [
      "Brand strategy",
      "Consumer research",
      "Creative development",
      "Creative production",
    ],
  },
  {
    imgFile: "laptop",
    title: "DIGITAL + SOCIAL MEDIA",
    topics: [
      "Content & social media strategy",
      "Content production",
      "Community management",
      "Paid social",
      "Influencer marketing",
      "UX, Design & Technology",
    ],
  },
  {
    imgFile: "shopping-cart",
    title: "SHOPPER MARKETING",
    topics: [
      "Path to purchase marketing",
      "In-store experience",
      "POS development",
      "Consumer promotions",
    ],
  },
  {
    imgFile: "calendar",
    title: "ACTIVATION",
    topics: [
      "Consumer experiences",
      "Events",
      "Experiential / brand activation",
    ],
  },
  {
    imgFile: "chat",
    title: "PR",
    topics: [
      "Communications strategy",
      "Reputation / crisis management",
      "Ambassadors",
      "Copywriting",
      "Media relations",
    ],
  },
]
