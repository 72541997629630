import { useStaticQuery, graphql } from "gatsby"

export const GetHomeBlockImages = () => {
  const data = useStaticQuery(
    graphql`
      query homeBlockImages {
        allFile(
          filter: { absolutePath: { regex: "/home-block-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(srcSetBreakpoints:[534,768], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp 
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allFile.edges
}
