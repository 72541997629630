import React, { useState } from "react"
import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid"
import Img from "gatsby-image"
import anime from "animejs/lib/anime.es.js"
import { Waypoint } from "react-waypoint"
import { homeServiceData } from "./homeServiceData"
import { GetServicesImages } from "../../queries/GetServicesImages"
import {
  Container,
  ImgContainer,
  MainTitle,
  Text,
  TeaserTitle,
  Topics,
  TeaserFlexCol,
} from "./style"

const animationTimeline = () => {
  const timeline = anime.timeline()

  timeline
    .add({
      targets: ".mainTitle",
      opacity: [0, 1],
      easing: "easeOutSine",
      duration: 500,
    })
    .add(
      {
        targets: ".text",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 500,
      },
      "+=100"
    )
    .add(
      {
        targets: ".teaserFlexCol",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 500,
        delay: (el, i) => 0 + 100 * i,
      },
      "+=200"
    )
}

const Service = () => {
  const [firstRun, setFirstRun] = useState(true)
  let dataLength = homeServiceData.length
  const serviceImages = GetServicesImages()

  let services = homeServiceData.map((service) => {
    return Object.assign({
      ...service,
      serviceImg: serviceImages.find((img) =>
        img.node.name.includes(service.imgFile)
      ),
    })
  })

  return (
    <Container>
      <FlexGrid gap={[0, 30, 0]} rowGap={[15, 27]}>
        <FlexRow>
          <FlexCol xs={12} sm={6}>
            <MainTitle className="mainTitle">
              It’s all in a day’s work
            </MainTitle>
            <Waypoint
              onEnter={() =>
                firstRun && (animationTimeline(), setFirstRun(false))
              }
            />
          </FlexCol>
          <FlexCol xs={11}>
            <Text className="text">
              We put Dulux paint cans in Vogue Living features. We build AI
              powered chatbots for Asahi Premium Beverages. We craft creative
              platforms that make brands, like Mayver’s Peanut Butter, number
              one in their category. We go beyond.
            </Text>
          </FlexCol>
          {services.map((service, index) => {
            let lastTeaser = index < dataLength - 1 ? 6 : 12
            return (
              <TeaserFlexCol
                xs={lastTeaser}
                sm={3}
                lg={2.5}
                key={index}
                className="teaserFlexCol"
              >
                <ImgContainer>
                  <Img fluid={service.serviceImg.node.childImageSharp.fluid} />
                </ImgContainer>
                <TeaserTitle>{service.title}</TeaserTitle>
                {service.topics.map((topic, index) => {
                  return <Topics key={index}>{topic}</Topics>
                })}
              </TeaserFlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
    </Container>
  )
}

export default Service
