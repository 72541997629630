import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Service } from "../components/HomeService"
import { HomeBlock } from "../components/HomeBlock"
import { HomeMasthead } from "../components/HomeMasthead"
import { GetHomeBlockImages } from "queries/GetHomeBlockImages"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const IndexPage = () => {
  const homeBlockImages = GetHomeBlockImages()
  const [showMenu, setShowMenu] = useState(false)

  // create our ref
  const blocks = useRef(null)

  const homeBlockData = [
    {
      title: "BIG AGENCY IMPACT",
      tag: "with small agency ingenuity",
      img: homeBlockImages[0],
      imgparallax: [25, -25],
    },
    {
      title: "WE GO BEYOND",
      tag: "to get the results you need",
      img: homeBlockImages[1],
      imgparallax: [12, -12],
    },
    {
      title: "BRAVE, STRATEGIC, CREATIVE",
      tag: "delivering integrated solutions",
      img: homeBlockImages[2],
      imgparallax: [25, -25],
    },
    {
      title: "SOLVING YOUR BIGGEST CHALLENGES",
      tag: "without forgetting the small stuff",
      img: homeBlockImages[3],
      imgparallax: [12, -12],
    },
  ]

  useScrollPosition(({ currPos }) => {
    if (-blocks.current.offsetTop > currPos.y) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  })

  return (
    <Layout template="home" showMenu={showMenu}>
      <SEO title="Home" />
      <HomeMasthead />

      <div ref={blocks}>
        {homeBlockData.map((block, index) => {
          return (
            <HomeBlock
              key={index}
              title={block.title}
              tag={block.tag}
              img={block.img}
              imgparallax={block.imgparallax}
            />
          )
        })}

        {/* <HomeBlock
            title={"BIG AGENCY IMPACT"}
            tag={"with small agency ingenuity"}
            img={homeBlockImages[0]}
          /> */}
      </div>
      <Service />
    </Layout>
  )
}

export default IndexPage
