import React, { useState, useEffect, useRef } from "react"
import { Waypoint } from "react-waypoint"
import { useSpring, useChain, animated } from "react-spring"
import { Parallax } from "react-scroll-parallax"
import { useMedia } from "../../hooks/useMedia"
import anime from "animejs/lib/anime.es.js"

import {
  HomeBlockContainer,
  HomeFlexGrid,
  HomeFlexRow,
  HomeFlexCol,
  HomeBlockImage,
  TextContainer,
} from "./style"

const HomeBlock = ({ title, tag, img, imgparallax }) => {
  const [animationTriggered, setAnimationTriggered] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(false)

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )
  const handleAnimationEnd = () => {
    const parallaxStarter = setTimeout(() => setAnimationEnded(true), 300)
    console.log("START parallax!!")
    return function cleanup() {
      clearTimeout(parallaxStarter)
    }
  }

  const bigTextFadeRef = useRef()
  const bigTextFade = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.7)",
    },
    opacity: 1,
    transform: "scale(1)",
    ref: bigTextFadeRef,
    config: {
      tension: 100,
      mass: 4,
    },
  })

  const smallTextFadeRef = useRef()
  const smallTextFade = useSpring({
    from: {
      opacity: 0,
    },
    opacity: 1,
    ref: smallTextFadeRef,
    config: {
      mass: 7,
    },
  })

  const imageFadeRef = useRef()
  const imageFade = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.7)",
    },
    opacity: 1,
    transform: "scale(1)",
    ref: imageFadeRef,
    config: {
      mass: 3,
      tension: 100,
    },
    onRest: () => handleAnimationEnd(),
  })

  useChain(
    animationTriggered ? [bigTextFadeRef, smallTextFadeRef, imageFadeRef] : [],
    [0.2, 0.4, 0.45],
    2000
  )

  const AnimatedImage = animated(HomeBlockImage)

  // anime.timeline({ loop: true }).add({
  //   targets: ".blockTitle .letters",
  //   opacity: [0, 1],
  //   scale: [0.2, 1],
  //   duration: 800,
  // })

  return (
    <HomeBlockContainer>
      <HomeFlexGrid gap={[40, 20, 40]} rowGap={[40, 0]}>
        <HomeFlexRow>
          <HomeFlexCol xs={12} sm={7}>
            <Parallax
              className="parallax__image"
              y={imgparallax ? imgparallax : [15, -15]}
              disabled={!isDesktop}
            >
              <AnimatedImage
                style={isDesktop ? imageFade : {}}
                space={img.node.name.includes("space")}
                fluid={img.node.childImageSharp.fluid}
              />
            </Parallax>
          </HomeFlexCol>
          <HomeFlexCol xs={12} sm={5}>
            <TextContainer>
              <Waypoint onEnter={() => setAnimationTriggered(true)} />
              <Parallax y={[8, -8]} disabled={!isDesktop}>
                <animated.h2 style={isDesktop ? bigTextFade : {}}>
                  {title}
                </animated.h2>
                <animated.p style={isDesktop ? smallTextFade : {}}>
                  {tag}
                </animated.p>
              </Parallax>
            </TextContainer>
          </HomeFlexCol>
        </HomeFlexRow>
      </HomeFlexGrid>
    </HomeBlockContainer>
  )
}

export default HomeBlock
