import React, { useEffect } from "react"
import { FlexGrid, FlexRow } from "components/FlexGrid"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import anime from "animejs/lib/anime.es.js"
import CMCLogo from "./cmc-logo-svg.js"
// import { ReactComponent as LogoTitle } from "images/cmc-logo--white.svg"

import {
  Masthead,
  MastheadFlexGrid,
  Subtitle,
  Body,
  LogoContainer,
  MiniNav,
  HeaderCarousel,
  CarouselImg,
} from "./style"

const HomeMasthead = () => {
  const imgsData = useStaticQuery(
    graphql`
      query homeImgs {
        allFile(
          filter: { absolutePath: { regex: "/home-banner-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(sizes: "100vw", quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imgs = imgsData.allFile.edges

  const animationTimeline = () => {
    // var textWrapper = document.querySelector(".welcome")
    // textWrapper.innerHTML = textWrapper.textContent.replace(
    //   /\S/g,
    //   "<span class='letter'>$&</span>"
    // )

    // var textWrapper2 = document.querySelector(".title")
    // textWrapper2.innerHTML = textWrapper2.textContent.replace(
    //   /\S/g,
    //   "<span class='letter2'>$&</span>"
    // )

    const timeline = anime.timeline()

    timeline
      .add({
        targets: ".navBar",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 640,
      })
      .add(
        {
          targets: ".subtitle",
          opacity: [0, 1],
          scale: [0.5, 1],
          easing: "easeOutSine",
          duration: 640,
        },
        "-=640"
      )
      .add(
        {
          targets: ".paragraph01",
          opacity: [0, 1],
          easing: "easeOutSine",
          duration: 330,
        },
        "+=800"
      )
      .add(
        {
          targets: ".paragraph02",
          opacity: [0, 1],
          easing: "easeOutSine",
          duration: 330,
        },
        "-=200"
      )
      .add(
        {
          targets: ".paragraph03",
          opacity: [0, 1],
          easing: "easeOutSine",
          duration: 330,
        },
        "-=200"
      )
  }

  useEffect(() => {
    animationTimeline()
  }, [])
  return (
    <Masthead>
      <MastheadFlexGrid>
        <FlexRow center="sm">
          <Body xs={12} sm={8}>
            <MiniNav direction="horizontal" className="navBar">
              <Link to="/about/">About</Link>
              <Link to="/work/">Work</Link>
              <Link to="/contact">Contact</Link>
            </MiniNav>

            <Subtitle className="subtitle">We are</Subtitle>
            <LogoContainer>
              <CMCLogo />
            </LogoContainer>

            <p className="paragraph01">
              An independent, full-service agency for 30+ years. We give our
              clients inspired creative work that works, whilst fiercely
              supporting our team to fuel their passions and sharpen their
              talent. No task is ever too big or too small, it’s always
              ‘challenge accepted.’ We like to call it CommuniCANdo.
            </p>

            <p className="paragraph02">
              From flying to San Fran to have MythBusters endorse Dulux paint;
              to wading in deep water to land a photo for Petstock; to
              partnering with musos, Client Liason + creating limited edition
              merch in record time, that’s a taste of how far we’ll go.
            </p>

            <p className="paragraph03">
              To get noticed, influence opinions and change behaviour, we go
              beyond.
            </p>
          </Body>
        </FlexRow>
      </MastheadFlexGrid>

      <HeaderCarousel interval={5} autoPlay hideDots hideButtons fade loop>
        <CarouselImg
          loading="eager"
          fluid={[
            imgs[0].node.childImageSharp.fluid,
            {
              ...imgs[1].node.childImageSharp.fluid,
              media: `(min-width: 600px)`,
            },
          ]}
        />
        <CarouselImg
          fluid={[
            imgs[2].node.childImageSharp.fluid,
            {
              ...imgs[3].node.childImageSharp.fluid,
              media: `(min-width: 600px)`,
            },
          ]}
        />
        <CarouselImg
          fluid={[
            imgs[4].node.childImageSharp.fluid,
            {
              ...imgs[5].node.childImageSharp.fluid,
              media: `(min-width: 600px)`,
            },
          ]}
        />
        <CarouselImg
          fluid={[
            imgs[6].node.childImageSharp.fluid,
            {
              ...imgs[7].node.childImageSharp.fluid,
              media: `(min-width: 600px)`,
            },
          ]}
        />
      </HeaderCarousel>
    </Masthead>
  )
}

export default HomeMasthead
