import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"

const CMCLogo = () => {
  const animationSVG = () => {
    const timeline = anime.timeline()
    timeline
      .add(
        {
          targets: ".SVGContainer",
          opacity: [0, 1],
          scale: [0.5, 1],
          easing: "easeOutSine",
          duration: 640,
        },
        "+=500"
      )
      // .add(
      //   {
      //     targets: ".lhs-line",
      //     scaleX: [0, 1],
      //     opacity: [1],
      //     easing: "linear",
      //     duration: 400,
      //   },
      //   "-=500"
      // )
      // .add(
      //   {
      //     targets: ".rhs-line",
      //     scaleX: [0, 1],
      //     opacity: [1],
      //     easing: "easeOutQuad",
      //     duration: 400,
      //   },
      //   "-=100"
      // )
      .add(
        {
          targets: ".dot",
          scale: [0, 1],
          opacity: [0, 1],
          duration: 900,
        },
        "-=300"
      )
  }
  useEffect(() => {
    animationSVG()
  }, [])

  return (
    <img
      src="/communicado-logo-ani.gif"
      alt="CMC Logo"
      className="SVGContainer"
      style={{ opacity: 0 }}
    />
  )
}

export default CMCLogo
