import styled from "styled-components"
import { device } from "breakpoints"

import { FlexCol } from "../FlexGrid"

export const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  @media ${device.desktop} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`
export const ImgContainer = styled.div`
  max-width: 102px;
  margin: 0 auto;
`
export const MainTitle = styled.h1`
  font-weight: 600;
  font-size: 64px;
  color: #ff7f41;
  letter-spacing: -0.17px;
  line-height: 60px;
  opacity: 0;

  @media ${device.desktop} {
    font-size: 96px;
    letter-spacing: 0;
    line-height: 96px;
  }
`
export const Text = styled.p`
  font-size: 16px;
  color: #3f3f3f;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 770px;
  opacity: 0;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 30px;
  }
`
export const TeaserTitle = styled.h6`
  font-weight: 700;
  font-size: 16px;
  color: #004e9a;
  letter-spacing: 0.4px;
  text-align: center;
  line-height: 24px;
  margin: 30px 0 20px;

  @media ${device.desktop} {
    font-size: 20px;
    letter-spacing: 0.5px;
    margin: 35px 0 20px;
  }
`
export const Topics = styled.p`
  font-size: 14px;
  color: #3f3f3f;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  margin-bottom: 13px;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 0;
  }
`
export const TeaserFlexCol = styled(FlexCol)`
  margin-top: 37px !important;
  opacity: 0;

  @media ${device.desktop} {
    margin-top: 65px !important;
  }
`
