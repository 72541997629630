import { useStaticQuery, graphql } from "gatsby"

export const GetServicesImages = () => {
  const data = useStaticQuery(
    graphql`
      query serviceImages {
        allFile(
          filter: { absolutePath: { regex: "/home-service/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allFile.edges
}
