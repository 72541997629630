import styled from "styled-components"
import { device } from "breakpoints"
// import { ReactComponent as LogoTitle } from "images/cmc-logo--white.svg"
import { FlexCol, FlexGrid } from "components/FlexGrid"
import { List } from "components/List"
import { Item } from "components/List/style"
import { Carousel } from "components/Carousel"
import AvenueImg from "components/AvenueImg"
import {
  EmblaComponent,
  SlidesContainer,
  Slide,
} from "components/Carousel/style"

export const MastheadFlexGrid = styled(FlexGrid)`
  z-index: 10;
`
export const HeaderCarousel = styled(Carousel)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  ${EmblaComponent},${SlidesContainer}{
    height: 100%;
  }

  ${Slide} {
   transition: opacity 3000ms !important;

  }
`

export const CarouselImg = styled(AvenueImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const Masthead = styled.div`
  background: #ff7f41;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-bottom: 10px;
  position: relative;

  @media ${device.large} {
    padding-bottom: 70px;
  }
`

export const Subtitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  font-family: ${(props) => props.theme.secondaryFont};
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 100%;
  opacity: 0;

  @media ${device.large} {
    margin-bottom: 18px;
    font-size: 36px;
  }
`

export const Body = styled(FlexCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    opacity: 0;
  }

  p {
    font-size: 18px;
    padding: 0 10px;
    margin-bottom: 25px;
    line-height: 133%;
    opacity: 0;

    @media ${device.large} {
      font-size: 24px;
      margin-bottom: 35px;
      letter-spacing: 0;
      padding: 0;
    }
  }
`

export const LogoContainer = styled.div`
  margin-bottom: 25px;

  svg {
    width: 100%;
    height: 100%;
    .dot {
      transform-origin: center;
      transform-box: fill-box;
    }
    /* .word,
    .lhs-line,
    .rhs-line {
      transform-origin: 0;
      transform-box: fill-box;
    }
    mask rect.rec {
      animation: wordAnimation 1s ease-in;
      animation-delay: 0.5s;
    }
    @keyframes wordAnimation {
      0% {
        transform: translateX(-756px);
      }
      100% {
        transform: translateX(0);
      }
    } */
  }

  @media (min-width: 360px) {
    width: 335px;
    height: 57px;
  }
  @media ${device.large} {
    height: 129px;
    width: 756px;
    margin-bottom: 38px;
  }
`

export const MiniNav = styled(List)`
  justify-content: center;
  padding-bottom: 36px;
  margin-top: 30px;
  opacity: 0;

  @media ${device.desktop} {
    padding-bottom: 80px;
    margin-top: 35px;
  }

  ${Item} {
    margin-right: 23px;

    &:last-child {
      margin: 0;
    }

    @media ${device.desktop} {
      margin-right: 60px;
    }
  }

  a {
    color: #fff;
    letter-spacing: 1.33px;
    font-family: ${(props) => props.theme.secondaryFont};
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }

    @media ${device.desktop} {
      font-size: 18px;
    }
  }
`
