import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import Img from "gatsby-image"

export const HomeFlexGrid = styled(FlexGrid)`
  height: 100%;
`

export const HomeFlexRow = styled(FlexRow)`
  height: 100%;
`

export const HomeFlexCol = styled(FlexCol)`
  position: relative;
  overflow: visible;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const HomeBlockImage = styled(Img)`
  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }

  @media ${device.desktop} {
    opacity: 0;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2 {
    font-size: 64px;
    color: #1e4c9e;

    margin-bottom: 6px;

    @media ${device.tablet} {
      font-size: 55px;
    }

    @media ${device.desktop} {
      font-size: 65px;
      opacity: 0;
    }

    @media ${device.large} {
      font-size: 80px;
      margin-bottom: 8px;
    }

    @media ${device.big} {
      font-size: 96px;
    }

    position: relative;

    .letters {
      position: absolute;
      margin: auto;
      left: 0;
      top: 0.3em;
      right: 0;
      opacity: 0;
    }
  }

  p {
    text-transform: lowercase;
    font-weight: 600;
    font-size: 22px;
    color: #1e4c9e;
    @media ${device.tablet} {
      font-size: 24px;
    }

    @media ${device.desktop} {
      font-size: 28px;
      opacity: 0;
    }
  }

  @media ${device.big} {
    width: 120%;
  }

  .parallax-inner {
    transition: all 400ms ease-out;
    /* transition-delay: 50ms; */
  }
`

export const HomeBlockContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
  background: white;

  @media ${device.mobile} {
    padding-bottom: 60px;
  }

  @media ${device.tablet} {
    height: 450px;
    padding: 0;
  }

  @media ${device.desktop} {
    height: 580px;
  }

  @media ${device.large} {
    height: 680px;
  }

  :nth-of-type(2n + 1) {
    background: none;

    ${TextContainer} {
      h2,
      p {
        color: #fa8337;
      }
    }

    @media ${device.tablet} {
      ${HomeFlexRow} {
        flex-direction: row-reverse;
      }
      .parallax__image {
        margin-right: auto;
        margin-left: 20px;
        left: 0;
        right: auto;
      }
    }
  }

  :nth-of-type(3) {
    ${HomeBlockImage} {
      @media ${device.tablet} {
        width: 90%;
      }
    }
  }

  :nth-of-type(4) {
    ${TextContainer} {
      h2 {
        font-size: 60px;

        @media ${device.tablet} {
          font-size: 50px;
        }

        @media ${device.desktop} {
          font-size: 58px;
        }

        @media ${device.large} {
          font-size: 72px;
          margin-bottom: 8px;
        }

        @media ${device.big} {
          font-size: 84px;
        }
      }
    }
  }

  .parallax__image {
    width: 100%;
    margin-top: ${(props) => props.space && " -30px"};

    @media ${device.tablet} {
      position: absolute !important;
      right: 0;
      margin-top: 0;
      margin-left: auto;
      margin-right: 20px;
      img {
        height: auto !important;
      }
    }

    @media ${device.large} {
      width: 110%;
      width: ${(props) => props.space && "124%"};
    }
  }
  .parallax-inner {
    transition: all 500ms ease-out;
  }
`
